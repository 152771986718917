import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import RelativeWrapper from '../../components/layout/RelativeWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import H1 from '@rotaready/frecl/build/H1';
import H3 from '@rotaready/frecl/build/H3';
import Text from '@rotaready/frecl/build/Text';
import H5 from '@rotaready/frecl/build/H5';
import Button from '@rotaready/frecl/build/Button';
import Pill from '@rotaready/frecl/build/Pill';
import RadioButtonGroup from '@rotaready/frecl/build/RadioButtonGroup';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';
import Margin from '@rotaready/frecl/build/Margin';

const TitleWrapper = styled.div`
  text-align: center;
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 80px 0;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const ContentArea = styled.div`
  flex: 1 1 auto;
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tile = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-bottom: 20px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: ${props => props.isFeature ? 'row' : 'column'};
    margin: 30px;
    flex: 0 1 calc(${props => props.isFeature ? '100%' : '50%'} - 60px);
  }

  @media (min-width: ${ViewportBreakpoints.desktopUpper}) {
    flex: 0 1 calc(${props => props.isFeature ? '66%' : '33%'} - 60px);
  }
`;

const TileFeatureImageContainer = styled.div`
  flex: 1 1 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 60%;
    margin: 30px 30px 30px 0;
    box-sizing: border-box;
  }
`;

const TileContentContainer = styled.div`
  flex: 1 1 ${props => props.isFeature ? '40%' : 'auto'};
  margin: 30px;
  box-sizing: border-box;
`;

const TileHeader = styled.div`
  margin-bottom: 20px;
`;

const TileTagContainer = styled.div`
  margin-bottom: 20px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const TileHeading = styled(H3).attrs({
  uistyle: 'brand160',
  fontWeight: 600,
})`
  &:hover {
    color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const TileText = styled(Text).attrs({
  size: 'xl',
})`
  margin-bottom: 20px;
`;

const TileType = styled.div`
  > * {
    display: inline-block;
  }
`;

const TileTypeText = styled(Text).attrs({
  uistyle: 'info',
})`
  padding-left: 5px;
`;

const FilterBar = styled.div`
  flex: 0 0 325px;
`;

const FilterBarHeading = styled(H5).attrs({
  uistyle: 'brand160',
})``;

const FilterOptions = styled.div`
  padding: 10px 0 20px 10px;
`;

const LinkWithArrow2 = styled(LinkWithArrow)`
  span {
    flex: none;
  }
`;

const FileIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'file-alt'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.info};
  font-size: 16px;
`;

const LayerGroupIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'layer-group'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.info};
  font-size: 16px;
`;

const InternalLink = styled(Link)`
  text-decoration: none;
`;

const categories = {
  all: {
    id: 'all',
    icon: null,
    name: 'All',
  },
  ebook: {
    id: 'ebook',
    icon: (<FileIcon />),
    name: 'Ebook',
    cta: 'Download eBook',
  },
  other: {
    id: 'other',
    icon: (<LayerGroupIcon />),
    name: 'Other',
    cta: 'Download',
  },
};

const categoryOptions = Object.entries(categories).map(([key, value]) => ({
  value: key,
  label: value.name,
}));

const industries = {
  all: {
    id: 'all',
    name: 'All',
  },
  hospitality: {
    id: 'hospitality',
    name: 'Hospitality',
  },
  leisure: {
    id: 'leisure',
    name: 'Leisure',
  },
  retail: {
    id: 'retail',
    name: 'Retail',
  },
};

const industryOptions = Object.entries(industries).map(([key, value]) => ({
  value: key,
  label: value.name,
}));

const tiles = [
  {
    heading: 'Key dates for hospitality - 2023/2024',
    category: categories.other,
    industries: [industries.hospitality],
    slug: 'resources/hospitality-calendar-2023',
    imageKey: 'calendar2023',
  },
  {
    heading: 'Key dates for hospitality - 2022/2023',
    category: categories.other,
    industries: [industries.hospitality],
    slug: 'resources/hospitality-calendar-2022',
    imageKey: 'calendar2022',
  },
  {
    heading: 'Key dates for hospitality - 2021/2022',
    category: categories.other,
    industries: [industries.hospitality],
    slug: 'resources/hospitality-calendar-2021',
    imageKey: 'calendar2021',
  },
  {
    heading: 'Free Excel rota template',
    text: 'Plan simple staff rotas with our free rota template',
    category: categories.other,
    industries: [industries.hospitality, industries.retail, industries.leisure],
    slug: 'free-excel-rota-template',
    imageKey: 'freeExcelRotaTemplate',
    feature: true,
  },
  {
    heading: 'The trends and tech changing the face of hospitality',
    category: categories.ebook,
    industries: [industries.hospitality],
    slug: 'resources/ebook-trends-tech-changing-face-hospitality',
    imageKey: 'hospitalityTrendsEbook',
  },
  {
    heading: 'The ultimate 2020 calendar for hospitality and retail',
    category: categories.other,
    industries: [industries.hospitality, industries.retail],
    slug: 'resources/ultimate-2020-calendar-hospitality-retail',
    imageKey: 'calendar2020',
  },
];

const header = {
  backgroundColor: 'grey10',
  invertColors: false,
};

class ResourcesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: categoryOptions[0].value,
      selectedIndustry: industryOptions[0].value,
    };
  }

  onCategoryChange = (selectedCategory) => {
    this.setState(() => ({ selectedCategory }));
  };

  onIndustryChange = (selectedIndustry) => {
    this.setState(() => ({ selectedIndustry }));
  };

  render() {
    const {
      data,
    } = this.props;

    const {
      selectedCategory,
      selectedIndustry,
    } = this.state;

    return (
      <Body header={header}>
        <SEO
          title="Resources - Rotaready"
          description="Insight, infographics, eBooks and more. For retail, leisure and hospitality industries."
          url="resources"
        />

        <RelativeWrapper backgroundColor="grey10">
          <ResponsiveContainer>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Resources" />
            </TitleWrapper>
          </ResponsiveContainer>
        </RelativeWrapper>

        <ResponsiveContainer>
          <ContentWrapper>
            <BodyWrapper>
              <FilterBar>
                <FilterBarHeading text="Type of resource" />

                <FilterOptions>
                  <RadioButtonGroup
                    name="category"
                    value={selectedCategory}
                    options={categoryOptions}
                    onChange={this.onCategoryChange}
                  />
                </FilterOptions>

                <FilterBarHeading text="Industry" />

                <FilterOptions>
                  <RadioButtonGroup
                    name="industry"
                    value={selectedIndustry}
                    options={industryOptions}
                    onChange={this.onIndustryChange}
                  />
                </FilterOptions>
              </FilterBar>

              <ContentArea>
                <TileContainer>
                  {tiles.filter((tile) => {
                    if (selectedCategory !== 'all' && tile.category.id !== selectedCategory) {
                      return false;
                    }

                    if (selectedIndustry !== 'all' && !tile.industries.some(i => i.id === selectedIndustry)) {
                      return false;
                    }

                    return true;
                  }).map((tile, index) => (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <Tile key={index} isFeature={tile.feature}>
                      <TileContentContainer isFeature={tile.feature}>
                        <TileHeader>
                          <TileTagContainer>
                            {tile.industries.map((industry, index) => (
                              <TileTag text={industry.name} key={index} />
                            ))}
                          </TileTagContainer>

                          <InternalLink to={`/${tile.slug}`}>
                            <TileHeading text={tile.heading} />
                          </InternalLink>
                        </TileHeader>

                        <TileText text={tile.text} />

                        <TileType>
                          {tile.category.icon}
                          <TileTypeText text={tile.category.name} />
                        </TileType>

                        {tile.feature
                        && (
                          <Margin marginTop={40}>
                            <div>
                              <Link to={`/${tile.slug}`}>
                                <Button uistyle="primary" text={tile.category.cta} size="lg" />
                              </Link>
                            </div>
                          </Margin>
                        )}
                      </TileContentContainer>

                      {tile.feature
                      && (
                        <TileFeatureImageContainer>
                          <GatsbyImage
                            image={data[tile.imageKey].childImageSharp.gatsbyImageData}
                            alt={tile.heading}
                            style={{ maxHeight: '420px' }}
                          />
                        </TileFeatureImageContainer>
                      )}

                      {!tile.feature
                      && (
                        <GatsbyImage
                          image={data[tile.imageKey].childImageSharp.gatsbyImageData}
                          alt={tile.heading}
                          style={{ maxHeight: '240px' }}
                        />
                      )}

                      {!tile.feature
                      && (
                        <TileContentContainer>
                          <LinkWithArrow2
                            direction="right"
                            to={`/${tile.slug}`}
                            text={tile.category.cta}
                            render={props => <Link {...props} />}
                          />
                        </TileContentContainer>
                      )}
                    </Tile>
                  ))}
                </TileContainer>
              </ContentArea>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default ResourcesPage;

export const query = graphql`
  query {
    calendar2023: file(relativePath: { eq: "resources/hospitality-calendar-2023/thumbnail.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    calendar2022: file(relativePath: { eq: "resources/hospitality-calendar-2022/thumbnail.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    calendar2021: file(relativePath: { eq: "resources/hospitality-calendar-2021/thumbnail.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    calendar2020: file(relativePath: { eq: "resources/ultimate-2020-calendar-hospitality-retail/thumbnail.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    hospitalityTrendsEbook: file(relativePath: { eq: "resources/ebook-trends-tech-changing-face-hospitality/thumbnail.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    freeExcelRotaTemplate: file(relativePath: { eq: "resources/free-excel-rota-template/thumbnail.png" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
